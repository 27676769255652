<template>
  <Header :isLogout="true" />
  <el-tabs v-model="activeName" :stretch="true" class="p-4">
    <!-- Create Tab Start -->
    <!-- <el-tab-pane label="Create" name="create" class="container"> -->
    <div class="flex w-full justify-start mb-3">
      <span class="text-sm text-avrillo font-semibold"
        >ID: #{{ formData.case_ref }}</span
      >
    </div>
    <el-form ref="form" :model="formData" :rules="rules" label-position="top">
      <!-- Case Search Start -->
      <div>
        <el-form-item prop="case_ref" label="Case Search">
          <el-select
            v-model="formData.case_ref"
            filterable
            remote
            reserve-keyword
            placeholder="Case Search"
            class="w-full"
            size="large"
            :loading="caseStore.loadings.list"
            :remote-method="caseStore.searchCase"
            @select="handleCaseSelect"
            :disabled="!caseStore.editPermission"
          >
            <el-option
              v-for="item in caseStore.conveyancingCases"
              :key="item.id"
              :label="`${item.reference_code} / ${item?.clients[0]?.title} ${item?.clients[0]?.name} ${item?.clients[0]?.surname}`"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <!-- Case Search End -->

      <!-- Correspondent Start -->
      <div class="mt-6">
        <el-form-item prop="correspondent" label="Correspondent">
          <el-select
            v-model="formData.correspondent"
            @change="handleCorrespondentChange"
            class="w-full"
            size="large"
            :disabled="formData.case_ref === null || !caseStore.editPermission"
            placeholder="Correspondent"
          >
            <el-option
              v-for="item in memorandumStore.correspondentTypes"
              :key="item.value"
              :label="item.title"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- Correspondent End -->

      <!-- Incoming Phrase Start -->
      <div class="mt-6">
        <el-form-item prop="phrase_id" label="Incoming Phrase">
          <el-select
            v-model="formData.phrase_id"
            @change="handlePhraseChange"
            class="w-full"
            size="large"
            :disabled="formData.case_ref === null || !caseStore.editPermission"
            placeholder="Incoming Phrase"
          >
            <el-option
              v-for="item in memorandumStore.phraseTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- If has choose the memorandum of sale on Incoming Phrase select box -->
        <router-link
          :to="{ name: 'MemorandumDetails' }"
          v-if="formData.phrase === 'Memorandum of Sale Request'"
        >
          <el-button
            class="bg-white border-0 p-0 hover:px-4 text-avrillo"
            :disabled="false"
          >
            Put Memorandum of Sale Details
            <i class="ph-caret-right align-middle ml-1"></i>
          </el-button>
        </router-link>
      </div>
      <!-- Incoming Phrase End -->

      <!-- Attachments Start -->
      <div v-if="formData.attachments.length > 0 && !caseStore.history?.id">
        <div class="mt-8">
          <span class="text-avrillo font-semibold">Attachments</span>
        </div>
        <div
          v-for="(attachment, index) in formData.attachments"
          :key="attachment.id"
          class="mt-3"
        >
          <div
            v-if="(!allAttachments && !attachment.isInline) || allAttachments"
          >
            <el-form-item
              size="large"
              :label="false"
              :prop="'attachments.' + index + '.value'"
              :rules="{
                required: false,
                message: 'This field is required',
              }"
            >
              <label
                :for="'attachments.' + index + '.value'"
                class="el-form-item__label truncated"
              >
                <el-popover
                  trigger="click"
                  v-if="attachment.contentType.substring(0, 5) === 'image'"
                  @after-enter="attachmentPreview(attachment.id)"
                  :width="300"
                  placement="top"
                  popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 10px;"
                >
                  <template #reference>
                    <el-text type="primary"><i
                      class="ph-eye"
                      style="display: inline-block;
                        bottom: -2px;
                        color: var(--el-color-primary) !important;
                        position: relative;
                        cursor:pointer;
                        margin-right: 6px;
                      "
                    ></i></el-text>
                  </template>
                  <template #default>
                    <div
                      class="demo-rich-content"
                      style="display: flex; gap: 16px; flex-direction: column"
                    >
                      <el-image
                        fit="scale-down"
                        style="width: auto"
                        :key="attachment.id"
                        :src="activePreviewItem"
                        lazy
                      >
                        <template #error>
                          <div class="image-slot">
                            <div
                              style="
                                display: block;
                                color: #888;
                                padding: 20px 0;
                                text-align: center;
                              "
                            >
                              Loading preview...
                            </div>
                          </div>
                        </template>
                      </el-image>
                    </div>
                  </template>
                </el-popover>
                {{ attachment.name }}</label
              >
              <el-select
                v-model="attachment.value"
                class="w-full"
                :placeholder="'No Phrase'"
                :disabled="
                  formData.case_ref === null || !caseStore.editPermission
                "
              >
                <el-option :label="'No Phrase'" :value="null"></el-option>
                <el-option
                  v-for="item in memorandumStore.phraseTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </div>
      <el-checkbox
        v-model="allAttachments"
        label="Show All Attachments"
        value="1"
        size="large"
      />

      <!-- Attachments End -->

      <!-- Button Start -->
      <div class="mt-8">
        <el-button
          v-if="caseStore.editPermission"
          class="w-full bg-primary border-0"
          type="primary"
          @click="handleSubmit"
          :loading="caseStore.loadings.store"
          :disabled="caseStore.loadings.list"
        >
          <span class="text-xs">{{
            caseStore.history?.id ? "Edit Case History" : "Add Case History"
          }}</span>
        </el-button>
      </div>
      <div class="mt-2">
        <el-button
          v-if="caseStore.editPermission && caseStore.history?.id"
          class="w-full border-0"
          @click="caseStore.editPermission = false"
          plain
          type="danger"
        >
          <span class="text-xs">Cancel Edit</span>
        </el-button>
      </div>
      <!-- Button End -->
    </el-form>
    <div class="mt-8">
      <el-button
        v-if="!caseStore.editPermission"
        class="w-full bg-primary border-0"
        type="primary"
        @click="caseStore.editPermission = true"
      >
        <span class="text-xs"> Now Edit</span>
      </el-button>
    </div>
  </el-tabs>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import Header from "../components/Header";
import { appAxios } from "@/utils/appAxios";
import { useCaseStore } from "@/store/CaseStore";
import { rules } from "@/utils/rules/case";
import { useMemorandumStore } from "@/store/MemorandumStore";
import { showNotification } from "../utils/notification";
const router = useRouter();
const activeName = ref("create");
const allAttachments = ref(false);
const activePreviewItem = ref("");

const memorandumStore = useMemorandumStore();
const caseStore = useCaseStore();

const { id, from } = window.Office.context.mailbox.initialData;
const formData = memorandumStore.formData;
const form = ref(null);

onMounted(async () => {
  await caseStore.getHistoryDetail(btoa(id)).then(() => {
    if (caseStore.history?.id) {
      formData.case_ref = caseStore.history?.data?.case_ref;
      formData.correspondent = caseStore.history?.data?.correspondent;
      formData.phrase_id = caseStore.history?.data?.phrase_id;
    }
    caseStore.editPermission =
      caseStore.history?.id && !caseStore.editPermission ? false : true;
    caseStore.getCaseById(caseStore.history?.data?.case_ref).then((res) => {
      caseStore.conveyancingCases.push({
        id: res.id,
        reference_code: res.reference_code,
        matter_type: res.matter_type,
        clients: res.clients,
      });
    });
  });

  await appAxios.get("static-data").then((data) => {
    memorandumStore.correspondentTypes = Object.keys(
      data.correspondent_type
    ).map((key) => {
      return {
        title: data.correspondent_type[key].replace("_", " "),
        value: data.correspondent_type[key],
      };
    });
    if (caseStore.history?.data) {
      handlePhraseChange(caseStore.history?.data?.phrase_id);
    }
  });

  fillCorrespondent();
});

const handleSubmit = () => {
  memorandumStore.loadingForm = true;
  form.value.validate((valid) => {
    if (formData.phrase === "Memorandum of Sale Request") {
      valid = valid && memorandumStore.memorandumValidate ? true : false;
    }
    if (valid) {
      window.Office.context.mailbox.getCallbackTokenAsync(
        { isRest: true },
        async function (result) {
          const mailItem = window.Office.context.mailbox.item;
          const ewsId = mailItem.itemId;
          const restId = window.Office.context.mailbox.convertToRestId(
            ewsId,
            window.Office.MailboxEnums.RestVersion.v2_0
          );
          const getMessageUrl =
            window.Office.context.mailbox.restUrl +
            "/v2.0/me/messages/" +
            restId +
            "/$value";

          const userProfile =
            window.Office.context.mailbox.officeAppContext.appOM.userProfile;

          const token = result.value;
          const inOut =
            userProfile.emailAddress === from.address ? "Out" : "In";

          caseStore.setLoadings(true);
          await axios
            .get(getMessageUrl, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((res) => {
              const requestData = {
                ...formData,
                attachments: formData.attachments.map((item) => {
                  return {
                    ...item,
                    phrase_id: item.value,
                    download_url: attachmentDownloadUrl(item.id, token),
                  };
                }),
                mime: btoa(res.data),
                type: "outlook",
                history_folder_id: 1,
                from: from.address,
                to: mailItem.to[0].emailAddress,
                in_out: inOut,
                send_date_time: mailItem.dateTimeCreated,
                details: "Email saved by outlook plugin",
                unique_id: btoa(id),
              };
              if (caseStore.history?.id) {
                caseStore
                  .editHistory(
                    formData.case_ref,
                    caseStore.history?.id,
                    requestData
                  )
                  .then(() => {
                    router.push({ name: "SuccessPage" });
                  })
                  .catch((e) => {
                    console.log(e);
                    showNotification(
                      "An error occurred while submitting the form.",
                      "error"
                    );
                  });
              } else {
                caseStore
                  .storeHistory(formData.case_ref, {
                    ...requestData,
                    type: "outlook",
                  })
                  .then(() => {
                    window.Office.context.mailbox.masterCategories.getAsync(
                      function (asyncResult) {
                        if (
                          asyncResult.status ===
                          window.Office.AsyncResultStatus.Succeeded
                        ) {
                          const masterCategories = asyncResult.value;
                          if (masterCategories && masterCategories.length > 0) {
                            const categoryToAdd = [
                              masterCategories[3].displayName,
                            ];
                            window.Office.context.mailbox.item.categories.addAsync(
                              categoryToAdd,
                              function (asyncResult) {
                                if (
                                  asyncResult.status ===
                                  window.Office.AsyncResultStatus.Succeeded
                                ) {
                                  router.push({ name: "SuccessPage" });
                                } else {
                                  showNotification(
                                    "Error adding Mail to Avrillo category!",
                                    "error"
                                  );
                                }
                              }
                            );
                          } else {
                            showNotification(
                              "Error adding Mail to Avrillo category!",
                              "error"
                            );
                          }
                        } else {
                          console.error(asyncResult.error);
                        }
                      }
                    );
                  })
                  .catch((e) => {
                    console.log(e);
                    showNotification(
                      "An error occurred while submitting the form.",
                      "error"
                    );
                  });
              }
            });
        }
      );
    } else if (
      memorandumStore.memorandumValidate === false &&
      formData.phrase === "Memorandum of Sale Request"
    ) {
      showNotification("Please fill in the memorandum form", "error");
      memorandumStore.loadingForm = false;
    } else {
      memorandumStore.loadingForm = false;
      return false;
    }
  });
};

const attachmentDownloadUrl = (attachmentID, token) => {
  const toEmail =
    window.Office.context.mailbox.initialData.userEmailAddress.replace(
      "@",
      "%40"
    );
  return `https://attachments.office.net/owa/${toEmail}/service.svc/s/GetFileAttachment?id=${attachmentID}&token=${token}&isDownload=true&animation=true`;
};

const attachmentPreview = (attachmentID) => {
  window.Office.context.mailbox.item.getAttachmentContentAsync(attachmentID, (res) => {
    activePreviewItem.value = `data:image/png;base64,${res.value.content}`
  })
};

const fillCorrespondent = () => {
  if (!caseStore.history?.id) {
    caseStore
      .getCorrespondent(formData.case_ref, from.address)
      .then((res) => {
        if (res.correspondent) formData.correspondent = res.correspondent;
      })
      .catch(() => {
        console.log("error");
      });
  }
};

const handleCorrespondentChange = (value) => {
  caseStore.getPhrases(value).then((phraseRes) => {
    memorandumStore.phraseTypes = phraseRes;
  });
};

const handlePhraseChange = (value) => {
  formData.phrase = memorandumStore.phraseTypes.find(
    (item) => item.id === value
  ).name;
};
</script>

<style>
.el-tabs {
  overflow: auto;
  height: calc(100vh - 50px);
}
.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: block;
  float: left;
}
</style>
