export const rules = {
    case_ref: {
        required: true,
        message: 'Case Ref field is required',
        trigger: 'blur'
    },
    correspondent: {
        required: true,
        message: 'Correspondent field is required',
        trigger: 'change'
    },
    phrase_id: {
        required: true,
        message: 'Incoming Phrase field is required',
        trigger: 'change'
    }
}
